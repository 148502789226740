import React, { useEffect, useState } from "react"

import Modal from "components/UI/Modal"
import FormWrap from "sections/Faq/FaqQuestion/components/FormWrap"
import Success from "sections/Faq/FaqQuestion/components/Success"

import useOptionsPages from "hooks/graphql/useOptionsPages"

const AskModal = ({ modalData, isOpen, closeModal }) => {
  const [isSuccess, setIsSuccess] = useState(false)
  const {
    askForm: {
      askQuestionFormGeneralSection: {
        formStateAskQuestion,
        successStateAskQuestion,
      },
    },
  } = useOptionsPages()

  useEffect(() => {
    setIsSuccess(state => !state)
  }, [])

  return (
    <Modal closeModal={closeModal} isOpen={isOpen}>
      <>
        {isSuccess ? (
          <FormWrap
            formData={formStateAskQuestion}
            modalData={modalData}
            isSuccess={setIsSuccess}
            closeModal={closeModal}
          />
        ) : (
          <Success data={successStateAskQuestion} closeModal={closeModal} />
        )}
      </>
    </Modal>
  )
}

export default AskModal
