import React, { useCallback } from "react"
import classnames from "classnames"
import { useForm } from "react-hook-form"
import { PaperAirplaneIcon } from "@heroicons/react/outline"

import { SendForm } from "api/forms"
import {
  emailValidationPattern,
  phoneValidationPattern,
  textValidationPattern,
} from "constans/common"

const AskForm = ({ data, isSuccess }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm()

  const onSubmit = useCallback(
    data => {
      const askFormData = {
        name: data.name,
        phone: data.phone,
        email: data.email,
        message: data.message,
      }

      SendForm(askFormData, "ask_form")

      // console.log(askFormData)

      isSuccess()
    },
    [isSuccess]
  )

  const elRef = useCallback(node => {
    if (node !== null) {
      if (!node.target.value.includes("+44")) {
        node.target.value = `+44${node.target.value}`
      }
    }
  }, [])

  return (
    <>
      <div className="h-full">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="h-full flex flex-col"
        >
          <p className="relative pb-4 mb-2">
            <input
              className="ss-default-input"
              type="text"
              placeholder={data?.namePlaceholder}
              {...register("name", {
                required: `'${data?.namePlaceholder}' field is required`,
                pattern: {
                  value: textValidationPattern,
                  message: `'${data?.namePlaceholder}' field is invalid`,
                },
              })}
            />

            <span className="ss-caption absolute left-0 bottom-0 text-error">
              {errors.name?.message}
            </span>
          </p>

          <p className="relative pb-4 mb-3">
            <input
              className="ss-default-input"
              type="tel"
              onClick={elRef}
              title="+44 (0)xx xxxx xxxx"
              maxLength="14"
              placeholder={data?.phonePlaceholder}
              {...register("phone", {
                required: `'${data?.phonePlaceholder}' field is required`,
                pattern: {
                  value: phoneValidationPattern,
                  message: `${data?.phonePlaceholder} is invalid`,
                },
              })}
            />
            <span className="ss-caption absolute left-0 bottom-0 text-error">
              {errors.phone?.message}
            </span>
          </p>

          <p className="relative pb-4 mb-3">
            <input
              className="ss-default-input"
              type="email"
              placeholder={data?.emailPlaceholder}
              {...register("email", {
                required: `'${data?.emailPlaceholder}' field is required`,
                pattern: {
                  value: emailValidationPattern,
                  message: `${data?.emailPlaceholder} is invalid`,
                },
              })}
            />
            <span className="ss-caption absolute left-0 bottom-0 text-error">
              {errors.email?.message}
            </span>
          </p>

          <textarea
            className="ss-default-input h-[40vh] sm:h-auto resize-none"
            rows="7"
            {...register("message", {
              required: "Your comment is required",
            })}
            placeholder={data?.textareaPlaceholder}
          ></textarea>

          <p className="text-center mt-auto sm:mt-8">
            <button
              type="submit"
              className={classnames(
                "ss-btn-base ss-btn-color-main group w-full sm:w-auto"
              )}
            >
              {data?.submitButtonTitle}
              <PaperAirplaneIcon className="w-5 sm:w-6 h-5 sm:h-6 rotate-45 ml-3 group-active:translate-x-4 group-active:-translate-y-4 group-active:scale-110 group-active:opacity-0 transition duration-75" />
            </button>
          </p>
        </form>
      </div>
    </>
  )
}

export default AskForm
