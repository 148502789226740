export const emailValidationPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const textValidationPattern = /^((([a-zA-Z ]+){2,16})|(([\u0401\u0451\u0410-\u044f ]+){2,16}))$/

export const phoneValidationPattern = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

export const defaultAvatar = '/images/common/default-avatar.png'
export const defaultClientFullImage = '/images/common/no-photo.jpg'

export const defaultSurveyButtonTitle = 'Get Started'
export const additionalSurveyButtonTitle = 'FREE consultation'

export const faqButtonTitle = 'View all answers'
export const casesButtonTitle = 'View All Cases'
