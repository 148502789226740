// Google fonts loading
require("@fontsource/mulish")
require("@fontsource/mulish/500.css")
require("@fontsource/mulish/600.css")
require("@fontsource/mulish/700.css")
// require("@fontsource/manrope")
// require("@fontsource/manrope/500.css")
// require("@fontsource/manrope/600.css")
// require("@fontsource/manrope/700.css")
require("@fontsource/lato")
require("@fontsource/lato/100.css")
require("@fontsource/lato/300.css")
require("@fontsource/lato/700.css")
require("@fontsource/lato/900.css")
require("@fontsource/roboto")
require("@fontsource/roboto/100.css")
require("@fontsource/roboto/300.css")
require("@fontsource/roboto/500.css")
require("@fontsource/roboto/700.css")
require("@fontsource/roboto/900.css")
require("@fontsource/inter")
require("@fontsource/inter/100.css")
require("@fontsource/inter/200.css")
require("@fontsource/inter/300.css")
require("@fontsource/inter/500.css")
require("@fontsource/inter/600.css")
require("@fontsource/inter/700.css")
require("@fontsource/inter/800.css")
require("@fontsource/inter/900.css")

require("./src/styles/global.scss")

const React = require("react")

const Layout = require("./src/sections/Layout/index.jsx").default

exports.onClientEntry = () => {
  const addTawkChat = () => {
    const script = document.createElement("script")

    script.innerHTML = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
      (function(){
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/63611defb0d6371309ccb05d/1ggplprq0';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
      })();
    `
    script.async = true
    document.body.appendChild(script)
  }

  setTimeout(() => {
    addTawkChat()
  }, 6000)
}

exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
