import React from "react"
import StaticImg from "components/StaticImg"

const Success = ({ data, closeModal }) => {
  return (
    <div className="h-full max-w-[390px] my-auto px-5 flex flex-col justify-center">
      {data?.title ? (
        <h3 className="ss-h3 text-text-title mb-2 text-center">{data.title}</h3>
      ) : null}
      {data?.subtitle ? (
        <p className="ss-body-1 text-text-regular text-center mb-8">
          {data.subtitle}
        </p>
      ) : null}

      {data?.image?.sourceUrl ? (
        <StaticImg
          path={data.image.sourceUrl}
          alt={data.image.sourceUrl.altText}
          className="mx-auto mb-16"
        />
      ) : null}

      <p className="text-center">
        <button
          type="button"
          className="ss-btn-base ss-btn-color-border w-full sm:w-auto"
          onClick={closeModal}
        >
          {data?.closeButton ?? "X"}
        </button>
      </p>
    </div>
  )
}

export default Success
