import {
  graphql,
  useStaticQuery
} from 'gatsby';

const flatListToHierarchical = (
  data = [], {
    idKey = 'id',
    parentKey = 'parentId',
    childrenKey = 'children'
  } = {}
) => {
  const tree = [];
  const childrenOf = {};

  data.forEach((item) => {
    const newItem = {
      ...item,
    };
    const {
      [idKey]: id, [parentKey]: parentId = 0
    } = newItem;
    childrenOf[id] = childrenOf[id] || [];
    newItem[childrenKey] = childrenOf[id];

    parentId
      ?
      (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem) :
      tree.push(newItem);
  });

  return tree;
};

const useMenu = (nameToMenu = 'bott_menu') => {
  const {
    allWpMenu: {
      nodes: menus
    },
  } = useStaticQuery(graphql `
    query getMenus {
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              parentId
              id
              order
              label
              path
              url
              connectedNode {
                node {
                  ... on WpPage {
                    id
                    status
                  }
                }
              }
              childItems {
                nodes {
                  order
                  label
                  path
                  connectedNode {
                    node {
                      ... on WpPage {
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menu = menus.find(({
    name
  }) => name === nameToMenu);

  if (!menu) return null;

  const {
    menuItems: {
      nodes
    },
  } = menu;

  return flatListToHierarchical(nodes);
};

export default useMenu;
