import React from "react"
import { ChevronLeftIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

import Svg from "components/SVG"

import stringToSlug from "helpers/stringToSlug"

const SubMenuModal = ({ data, handleClick }) => {
  return (
    <div className="fixed inset-0 w-full h-full bg-white z-[11] px-4 !mt-[15px] lg:!mt-9">
      <p className="relative flex justify-center items-center bg-grey-bg py-2.5 px-4 -mx-4 mb-4">
        <button
          onClick={handleClick}
          type="button"
          className="absolute top-1/2 left-4 h-6 w-6 -translate-y-1/2"
        >
          <ChevronLeftIcon className="h-6 w-6 text-secondary-main-s" />
        </button>

        <span className="font-lato font-bold text-lg text-text-title flex justify-start items-center gap-3">
          {/* <span className="w-8 h-8">
            <img
              src={data.groupIcon.sourceUrl}
              alt={data.groupIcon.altText}
              width="32"
              height="32"
              className="w-8 h-8"
            />
          </span> */}
          {data.groupName}
        </span>
      </p>

      <ul className="space-y-3">
        {data.groupList[0].groupListItem.map(item => {
          return (
            <li key={item.uri}>
              <Link
                to={item.uri}
                className="font-roboto font-medium text-sm text-black flex justify-start items-center gap-3 text-left"
              >
                <Svg
                  name={stringToSlug(item.title)}
                  className="text-accent-bright-blue w-8 h-8 flex-none"
                />
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SubMenuModal
