import {
  graphql,
  useStaticQuery
} from 'gatsby';

const useOptionsPages = () => {
  const {
    allWp: {
      nodes
    },
  } = useStaticQuery(graphql `
    query getOptions {
      allWp {
        nodes {
          header {
            header {
              logoHeader {
                altText
                sourceUrl
              }
              linkHeader {
                ...on WpPage {
                  id
                  title
                  uri
                  slug
                  link
                }
              }
              navigationHeader {
                linkNavigationHeader {
                  ...on WpPage {
                    id
                    title
                    uri
                    slug
                  }
                }
                dropdownNavigationHeader {
                  ...on WpPage {
                    id
                    title
                    slug
                    uri
                  }
                }
                megaDropdown {
                  dropdownTitle
                  dropdownList {
                    groupName
                    groupIcon {
                      altText
                      sourceUrl
                    }
                    groupList {
                      groupListItem {
                        ...on WpPage {
                          id
                          title
                          uri
                          slug
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          boostSales {
            boostSalesGeneralSection {
              titleBoostSales
              subtitleBoostSales
              linkBoostSales {
                ...on WpPage {
                  id
                  title
                  uri
                  slug
                }
              }
              listBoostSales {
                titleListBoostSales
                imageListBoostSales {
                  altText
                  sourceUrl
                }
              }
            }
          }
          stay {
            stayInTouch {
              imageStayInTouchForm1 {
                altText
                sourceUrl
              }
              titleStayInTouchForm1
              subtitleStayInTouchForm1
              linkStayInTouchForm1 {
                ...on WpPage {
                  id
                  title
                  uri
                  slug
                }
              }
              imageStayInTouchForm2 {
                altText
                sourceUrl
              }
              titleStayInTouchForm2
              subtitleStayInTouchForm2
              emailPlaceholderStayInTouchForm
              emailSuccessMessageStayInTouchForm
              emailInvalidMessageStayInTouchForm
              emailRequiredMessageStayInTouchForm
            }
          }
          askForm {
            askQuestionFormGeneralSection {
              formStateAskQuestion {
                namePlaceholder
                phonePlaceholder
                emailPlaceholder
                textareaPlaceholder
                submitButtonTitle
              }
              successStateAskQuestion {
                title
                subtitle
                image {
                  altText
                  sourceUrl
                }
                closeButton
              }
            }
          }
          contactForm {
            contactFormFields {
              contactFormCommentsTextarea
              contactFormEmailInput
              contactFormNameInput
              contactFormPhoneInput
              contactFormSubmitButton
              contactFormSuccessState {
                contactFormSuccessTitle
                contactFormSuccessSubtitle
                contactFormSuccessImage {
                  altText
                  sourceUrl
                }
              }
            }
          }
          footer {
            footer {
              topFooter {
                telephoneTopFooter
                registerInfoTopFooter
                emailTopFooter
                logoTopFooter {
                  altText
                  sourceUrl
                }
                socialsTopFooter {
                  socialNameTopFooter
                  socialLinkTopFooter
                }
              }
              bottomFooter {
                copyrightInfoBottomFooter
                pagesLinkBottomFooter {
                  ...on WpPage {
                    id
                    title
                    uri
                    slug
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return nodes[0];
};

export default useOptionsPages;
