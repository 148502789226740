import React, { useEffect } from "react"
import { useLocation } from "@reach/router"
import { clearAllBodyScrollLocks } from "body-scroll-lock"
import ScrollToTop from "react-scroll-to-top"

import Header from "@/sections/Layout/Header"
import Footer from "@/sections/Layout/Footer"
import Svg from "components/SVG"

const Layout = ({ children }) => {
  const location = useLocation()

  // clean all scroll locks on page
  useEffect(() => {
    clearAllBodyScrollLocks()
  }, [location.href])

  return (
    <>
      <div className="relative h-screen">
        <ScrollToTop
          smooth
          top="4000"
          className="!flex !justify-center !items-center !bottom-28 !right-6 !rounded-full !shadow-none !w-12 sm:!w-16 !h-12 sm:!h-16 !border sm:!border-2 !border-solid !border-accent-dark-blue transition-all hover:bg-accent-dark-blue group"
          component={
            <Svg
              style={{ width: "22px", height: "11px" }}
              name="chevron-to-top"
              className="!w-4 sm:!w-6 !h-4 sm:!h-3.5 text-accent-dark-blue group-hover:text-white transition-all"
            />
          }
        />
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

export default Layout
