import {
  useState,
  useEffect
} from "react"

import windowAvailable from "helpers/windowAvailable"

// TODO: двойной рендер
const useScrollDirection = () => {
  // const isWindowAvailable = windowAvailable()

  const [scrollDir, setScrollDir] = useState("")

  useEffect(() => {
    const threshold = 1
    let lastScrollY = window.pageYOffset
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setScrollDir(scrollY > lastScrollY ? "down" : "up")
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [])

  return windowAvailable() ? scrollDir : ''

}

export default useScrollDirection
