import React from "react"
import classnames from "classnames"
import { XIcon } from "@heroicons/react/solid"

import Svg from "components/SVG"

const BurgerButton = ({ isOpen, onClick, className }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames("z-10", className)}
    >
      {!isOpen ? (
        <Svg name={"burger-btn"} className="w-6 h-6 text-black" />
      ) : (
        <XIcon className="h-5 w-5 text-black" />
      )}
    </button>
  )
}

export default BurgerButton
