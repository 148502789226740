import React from "react"
import classnames from "classnames"

import Svg from "components/SVG"

const SocialsItem = ({
  socialsName,
  color,
  border = "1px solid",
  size = "",
}) => {
  return (
    <span
      className={classnames(
        "flex justify-center items-center w-10 h-10 rounded-full border group xl:hover:bg-bg-p/10 transition-colors"
      )}
      style={{ border: `${border} ${color}`, color: color }}
    >
      <Svg
        name={socialsName}
        className={classnames(
          "w-7 h-7 xl:group-hover:opacity-80 xl:transition-opacity",
          size === "large" ? "!w-10 !h-10" : ""
        )}
      />
    </span>
  )
}

export default SocialsItem
