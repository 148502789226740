import React from "react"

import HeaderCompponent from "sections/Layout/Footer/components/HeaderComponent"
import FooterNav from "sections/Layout/partials/FooterNav"
import FooterNavMobile from "sections/Layout/partials/FooterNavMobile"
import FooterComponent from "sections/Layout/Footer/components/FooterComponent"

import useWindowSize from "hooks/useWindowResize"

const Footer = () => {
  const { width } = useWindowSize()

  return (
    <footer className="pt-12 lg:pt-20 pb-12 lg:pb-14 text-white bg-dark-2">
      <div className="fluid-container">
        <HeaderCompponent />

        {width >= 641 ? <FooterNav /> : <FooterNavMobile />}

        <FooterComponent />
      </div>
    </footer>
  )
}

export default Footer
