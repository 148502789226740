import {
  useState,
  useEffect
} from 'react';

import windowAvailable from "helpers/windowAvailable"

const useWindowSize = () => {
  const isWindowAvailable = windowAvailable()

  const [windowSize, setWindowSize] = useState({
    width: isWindowAvailable ? window.innerWidth : null,
    height: isWindowAvailable ? window.innerHeight : null,
  });


  useEffect(() => {

    const handleResize = () => {
      if (isWindowAvailable) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
    };

    if (isWindowAvailable) {
      window.addEventListener('resize', handleResize);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [isWindowAvailable]);

  return windowSize;
};

export default useWindowSize;
