import React, { useCallback, useEffect, useState } from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

const Group = ({ nav }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { href } = useLocation()

  const setOpen = useCallback(() => {
    setIsOpen(state => !state)
  }, [])

  useEffect(() => {
    setIsOpen(false)
  }, [href])

  return (
    <div className="mb-5" key={nav.label}>
      {nav.label !== "#" ? (
        <button
          className="ss-subtitle-1 w-full text-text-regular-2 cursor-pointer select-none flex justify-between items-center"
          onClick={setOpen}
          onKeyDown={setOpen}
          type="button"
        >
          {nav.label}

          <svg
            className={classnames(
              isOpen ? "rotate-180" : "",
              "transition-transform"
            )}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
          >
            <path
              d="M6 11.1426L12 17.1426L18 11.1426"
              stroke="#808099"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      ) : (
        <></>
      )}

      {isOpen ? (
        <ul className="space-y-2 pt-4">
          {nav.children.length > 0 ? (
            nav.children.map(subItem => {
              return (
                <li key={subItem.label}>
                  <Link to={subItem.path} className="">
                    {subItem.label}
                  </Link>
                </li>
              )
            })
          ) : (
            <></>
          )}
        </ul>
      ) : (
        <></>
      )}
    </div>
  )
}

export default Group
