export const GET_STARTED = "#get-started"
export const ANSWERS = "#answers"
export const ARTICLES = "#articles"

export const AmazonAccountManagmentPage = "/amazon-account-management/"
export const AmazonListintOptimizationPage = "/amazon-listining-optimization/"
export const EbayListingOptimizationPage = "/ebay-listing-optimization/"
export const LinnworksSetupAndImplementationPage =
  "/linnworks-setup-and-implementation/"
export const AmazonSeoPage = "/amazon-seo/"
export const FaqPage = "/faq/"
export const BlogPage = "/blog/"
export const CasesPage = "/cases/"
export const TestimonialsPage = "/testimonials/"

export const BlogArticlePageTemp = "/blog-article/"
export const CasesArticlePageTemp = "/cases-article/"
export const ServicesPage = "/services/"
