import React from "react"
import { Link } from "gatsby"

const ServicesLink = () => {
  return (
    <Link
      to="/services"
      className="text-base font-semibold tracking-wide inline-block text-accent-dark-blue md:mt-6 hover:text-opacity-70 transition-all"
    >
      View all services
    </Link>
  )
}

export default ServicesLink
