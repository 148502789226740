import React from "react"
import { Link } from "gatsby"

import useOptionsPages from "hooks/graphql/useOptionsPages"
import MegaDropdown from "./MegaDropdown"
import Dropdown from "./Dropdown"

const HeaderNav = () => {
  const {
    header: {
      header: { navigationHeader },
    },
  } = useOptionsPages()

  return (
    <nav className="flex justify-center items-center space-x-10">
      {navigationHeader?.length > 0
        ? navigationHeader?.map((item, idx) => {
            if (item.megaDropdown.dropdownTitle !== null) {
              return (
                <MegaDropdown
                  item={item}
                  key={item.megaDropdown.dropdownTitle}
                />
              )
            }

            if (item.dropdownNavigationHeader === null) {
              return (
                <Link
                  key={item?.linkNavigationHeader?.title}
                  to={item?.linkNavigationHeader?.uri ?? "#"}
                  className="ss-button ss-link text-text-title"
                >
                  {item?.linkNavigationHeader?.title}
                </Link>
              )
            }

            if (item.dropdownNavigationHeader.length > 0) {
              return <Dropdown item={item} key={idx} title={"Resources"} />
            }

            return null
          })
        : null}
    </nav>
  )
}

export default HeaderNav
