import React from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

import Svg from "components/SVG"

import stringToSlug from "helpers/stringToSlug"

const DisclosureComponent = ({ navItem, title, idx }) => {
  return (
    <Disclosure
      as="div"
      className=""
      key={navItem?.linkNavigationHeader?.title + "dropdown"}
    >
      {({ open }) => (
        <>
          <Disclosure.Button className="flex justify-start items-center w-full outline-none text-text-title">
            <p className="flex justify-start items-center gap-3">
              <Svg
                name={title === "Resources" ? "resources" : "services"}
                className="text-accent-bright-blue w-8 h-8"
              />

              <span className="font-lato font-semibold text-lg text-text-title">
                {title}
              </span>
            </p>

            <ChevronDownIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } ml-auto h-6 w-6 text-secondary-main-s`}
            />
          </Disclosure.Button>

          <Disclosure.Panel className="font-roboto text-sm font-semibold tracking-wide text-text-title mt-4 border-t border-grey-1">
            <ul className="space-y-4 py-2 rounded-sm">
              {navItem.dropdownNavigationHeader.length > 0
                ? navItem.dropdownNavigationHeader.map(navSubItem => {
                    return (
                      <li key={navSubItem.title + "subitem"} className="block">
                        <Link
                          to={navSubItem.uri}
                          className="flex justify-start items-center gap-3"
                        >
                          <Svg
                            name={stringToSlug(navSubItem.title)}
                            className="text-accent-bright-blue w-8 h-8"
                          />
                          {navSubItem.title}
                        </Link>
                      </li>
                    )
                  })
                : null}
            </ul>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default DisclosureComponent
