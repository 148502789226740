import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"

import useMenu from "hooks/graphql/useMenu"

const NoServiceLinks = () => {
  const menus = useMenu()

  return (
    <div className="columns-2 gap-10 lg:gap-16 mt-12 md:mt-0">
      {menus.length > 0
        ? menus.slice(-2).map((nav, idx) => {
            return (
              <ul key={nav.label} className={"break-inside-avoid mb-12"}>
                <li className="ss-subtitle-1 text-text-regular-2 mb-6">
                  {nav.label}
                </li>

                <li>
                  <ul className="space-y-4">
                    {nav.children.length > 0
                      ? nav.children.map(subItem => {
                          return (
                            <li key={subItem.label}>
                              <Link
                                to={subItem.path}
                                className={classnames(
                                  "ss-body-1 ss-link hover:opacity-70 transition-opacity",
                                  {
                                    "after:content-['Coming_soon'] after:text-text-regular-2 after:ss-caption after:ml-4 pointer-events-none":
                                      subItem.connectedNode === null ||
                                      subItem.connectedNode.node === null,
                                  }
                                )}
                              >
                                {subItem.label}
                              </Link>
                            </li>
                          )
                        })
                      : null}
                  </ul>
                </li>
              </ul>
            )
          })
        : null}
    </div>
  )
}

export default NoServiceLinks
