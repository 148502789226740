import React from "react"

import useOptionsPages from "hooks/graphql/useOptionsPages"
import DisclosureComponent from "./DisclosureComponent"
import LinkComponent from "./LinkComponent"
import GroupItem from "./GroupItem"

const HeadeNavMobile = () => {
  const {
    header: {
      header: { navigationHeader },
    },
  } = useOptionsPages()

  return (
    <div className="w-full px-2 lg:pr-6">
      <div className="space-y-10">
        {navigationHeader.length > 0
          ? navigationHeader.map((navItem, idx) => {
              if (navItem.megaDropdown.dropdownList !== null) {
                return (
                  <GroupItem
                    data={navItem.megaDropdown.dropdownList}
                    key={idx}
                  />
                )
              }

              if (navItem.linkNavigationHeader !== null) {
                return <LinkComponent navItem={navItem} key={idx} />
              }

              if (navItem.dropdownNavigationHeader !== null) {
                return (
                  <DisclosureComponent
                    navItem={navItem}
                    title={"Resources"}
                    idx={idx}
                    key={idx}
                  />
                )
              }

              return null
            })
          : null}
      </div>
    </div>
  )
}

export default HeadeNavMobile
