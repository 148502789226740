import React from "react"
import { Link } from "gatsby"

import Svg from "components/SVG"
import stringToSlug from "helpers/stringToSlug"

const LinkComponent = ({ navItem }) => {
  return (
    <Link
      key={navItem?.linkNavigationHeader?.title}
      to={navItem?.linkNavigationHeader?.uri}
      className="font-lato font-semibold text-lg text-text-title flex justify-start items-center gap-3 text-left"
    >
      <Svg
        name={stringToSlug(navItem?.linkNavigationHeader?.title)}
        className="text-accent-bright-blue w-8 h-8 rounded-full"
      />
      {navItem?.linkNavigationHeader?.title}
    </Link>
  )
}

export default LinkComponent
