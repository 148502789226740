import React, { Fragment, useState, useCallback } from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

import Svg from "components/SVG"

import { ServicesPage } from "constans/urls"

const MegaDropdown = ({ item }) => {
  const [activeGroup, setActiveGroup] = useState("Amazon")

  const handleActiveGroup = useCallback(e => {
    setActiveGroup(e.target.dataset.title)
  }, [])

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="ss-button ss-link text-text-title inline-flex w-full justify-center gap-1 px-1 py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        {item.megaDropdown.dropdownTitle}
        <ChevronDownIcon className="w-6 h-6" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="ss-header-submenu p-6 absolute -left-24 mt-4 w-[1000px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none flex justify-start items-start gap-8">
          <div className="space-y-4">
            {item.megaDropdown.dropdownList?.length > 0
              ? item.megaDropdown.dropdownList.map(subItem => {
                  return (
                    <div key={subItem.groupName} className="">
                      <button
                        type="button"
                        data-title={subItem.groupName}
                        onMouseOver={handleActiveGroup}
                        onFocus={handleActiveGroup}
                        className={classnames(
                          "ss-h4 flex justify-start items-center gap-3 py-6 px-8 rounded-lg w-72 cursor-pointer",
                          activeGroup === subItem.groupName
                            ? "ring-2 ring-main"
                            : "ring-1 ring-grey-3"
                        )}
                      >
                        <span className="flex-none inline-block w-8 h-8 pointer-events-none">
                          {subItem.groupIcon.sourceUrl ? (
                            <img
                              src={subItem.groupIcon.sourceUrl}
                              alt={subItem.groupIcon.altText}
                              className="w-8 h-8 rounded-full"
                              width="32"
                              height="32"
                            />
                          ) : (
                            <></>
                          )}
                        </span>

                        <span className="pointer-events-none">
                          {subItem.groupName}
                        </span>
                      </button>
                    </div>
                  )
                })
              : null}
            <Menu.Item>
              <Link
                to={ServicesPage}
                className="ss-button ss-link inline-flex justify-start items-center gap-2 !mt-8 text-accent-dark-blue group"
              >
                View all services
                <Svg
                  name="arrow-right"
                  className="text-accent-dark-blue w-6 h-6 flex-none transition-transform group-hover:translate-x-1"
                />
              </Link>
            </Menu.Item>
          </div>

          <div>
            {item.megaDropdown.dropdownList?.length > 0 ? (
              item.megaDropdown.dropdownList.map(subItem => {
                return (
                  <div
                    key={subItem.groupName}
                    className={
                      activeGroup === subItem.groupName ? "block" : "hidden"
                    }
                  >
                    <div
                      className={classnames(
                        subItem.groupList[0].groupListItem.length > 10
                          ? "columns-2"
                          : ""
                      )}
                    >
                      {subItem.groupList[0].groupListItem.length > 0 ? (
                        subItem.groupList[0].groupListItem.map(item => {
                          return (
                            <Menu.Item
                              key={item.uri}
                              className="ss-link font-roboto font-medium text-base flex justify-start items-center gap-3 [&:not(:last-child)]:mb-4"
                            >
                              <Link to={item.uri}>
                                <Svg
                                  name={item.slug}
                                  className="text-accent-bright-blue w-8 h-8 flex-none"
                                />
                                <span>{item.title}</span>
                              </Link>
                            </Menu.Item>
                          )
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )
              })
            ) : (
              <></>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default MegaDropdown
