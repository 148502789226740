import React from "react"

import StaticImg from "components/StaticImg"
import SocialsList from "components/SocialsList"

import useOptionsPages from "hooks/graphql/useOptionsPages"

const HeaderComponent = () => {
  const {
    footer: {
      footer: {
        topFooter: {
          telephoneTopFooter,
          emailTopFooter,
          registerInfoTopFooter,
          logoTopFooter,
          socialsTopFooter,
        },
      },
    },
  } = useOptionsPages()

  const socials = socialsTopFooter.map(social => ({
    icon: social?.socialNameTopFooter,
    url: social?.socialLinkTopFooter,
  }))

  return (
    <div className="md:flex md:justify-start md:items-center md:gap-8 xl:gap-16 mb-12 md:mb-10 md:pb-10 md:border-b md:border-secondary-light-s">
      <div className="md:mr-auto mb-8 md:mb-0">
        <StaticImg
          path={logoTopFooter.sourceUrl ?? "#"}
          alt={logoTopFooter.altText}
          className="w-32 xl:w-72 mb-4"
          loading="lazy"
          width="288"
          height="82"
        />
        <p className="ss-caption text-text-regular-2 max-w-[340px]">
          {registerInfoTopFooter ?? ""}
        </p>
      </div>

      <div className="xl:flex xl:justify-start xl:items-center xl:gap-16 mb-4 md:mb-0 space-y-2 md:space-y-0">
        <p>
          <a
            href={`tel:${telephoneTopFooter ?? ""}`}
            className="ss-link ss-lead-reg sm:text-2xl text-white font-medium"
          >
            {telephoneTopFooter ?? ""}
          </a>
        </p>

        <p>
          <a
            href={`mailto:${emailTopFooter ?? ""}`}
            className="ss-link ss-lead-reg sm:text-2xl text-white font-medium"
          >
            {emailTopFooter ?? ""}
          </a>
        </p>
      </div>

      <SocialsList data={socials} color="#fff" />
    </div>
  )
}

export default HeaderComponent
