import React from "react"
import { Link } from "gatsby"
import classnames from "classnames"

import HeaderNavMobile from "sections/Layout/partials/HeaderNavMobile"
import SocialsList from "components/SocialsList"

import useOptionsPages from "hooks/graphql/useOptionsPages"
import { defaultSurveyButtonTitle } from "constans/common"

import { menu } from "./style.module.scss"

const MobileMenu = () => {
  const {
    header: {
      header: { linkHeader },
    },
    footer: {
      footer: {
        topFooter: {
          telephoneTopFooter,
          emailTopFooter,
          registerInfoTopFooter,
          socialsTopFooter,
        },
      },
    },
  } = useOptionsPages()

  const socials = socialsTopFooter.map(social => ({
    icon: social?.socialNameTopFooter,
    url: social?.socialLinkTopFooter,
  }))

  return (
    <div className="fluid-container pt-16 sm:pt-28 bg-white h-full">
      <div className={classnames(menu, "h-[80vh] overflow-y-scroll")}>
        <div className="mb-12">
          <HeaderNavMobile />
        </div>

        <a href={`tel:${telephoneTopFooter}`} className="ss-h5 block mb-2">
          {telephoneTopFooter}
        </a>

        <a href={`mailto:${emailTopFooter}`} className="ss-h5 block mb-6">
          {emailTopFooter}
        </a>

        <p className="ss-caption text-text-regular-2 mb-6 w-10/12 sm:w-3/5">
          {registerInfoTopFooter}
        </p>

        <SocialsList data={socials} color="#211E31" />

        {linkHeader?.length > 0 ? (
          <Link
            to={linkHeader[0]?.uri}
            className="ss-btn-base block w-full mt-8"
          >
            {defaultSurveyButtonTitle}
          </Link>
        ) : null}
      </div>
    </div>
  )
}

export default MobileMenu
