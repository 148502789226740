import React from "react"
import { Link } from "gatsby"

import Group from "./Group"
import ServicesLink from "../ServicesLink"
import NoServiceLinks from "../NoServiceLinks"

import useMenu from "hooks/graphql/useMenu"

const FooterNavMobile = () => {
  const menus = useMenu()

  return (
    <nav>
      {menus.length > 0 ? (
        menus.slice(0, 6).map((nav, idx) => {
          return <Group nav={nav} key={nav.label} />
        })
      ) : (
        <></>
      )}

      <ServicesLink />

      {/* Display Linnworks nav link */}
      {menus.length > 0 ? (
        menus
          .filter(item => item.label === "#")
          .map((nav, idx) => {
            return (
              <ul className="space-y-2 pt-4" key={nav.label}>
                {nav.children.length > 0 ? (
                  nav.children.map(subItem => {
                    return (
                      <li key={subItem.label}>
                        <Link to={subItem.path} className="">
                          {subItem.label}
                        </Link>
                      </li>
                    )
                  })
                ) : (
                  <></>
                )}
              </ul>
            )
          })
      ) : (
        <></>
      )}
      {/* Display Linnworks nav link */}

      <NoServiceLinks />
    </nav>
  )
}

export default FooterNavMobile
