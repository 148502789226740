import React, { useCallback, useState } from "react"
import { ChevronRightIcon } from "@heroicons/react/solid"
import SubMenuModal from "./SubMenuModal"

const GroupItemElement = ({ item }) => {
  const [open, setOpen] = useState(false)

  const handleOpen = useCallback(() => {
    setOpen(state => !state)
  }, [])

  return (
    <>
      <button
        type="button"
        className="flex justify-between items-center w-full"
        onClick={handleOpen}
      >
        <span className="font-lato font-semibold text-lg text-text-title flex justify-start items-center gap-3">
          <img
            src={item.groupIcon.sourceUrl}
            alt={item.groupIcon.altText}
            className="w-8 h-8 rounded-full"
          />
          {item.groupName}
        </span>

        <ChevronRightIcon className="h-6 w-6 text-secondary-main-s" />
      </button>

      {open ? <SubMenuModal handleClick={handleOpen} data={item} /> : <></>}
    </>
  )
}

export default GroupItemElement
