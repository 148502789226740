import React from "react"
import { Link } from "gatsby"

import useOptionsPages from "hooks/graphql/useOptionsPages"

const Footer = () => {
  const {
    footer: {
      footer: {
        bottomFooter: { copyrightInfoBottomFooter, pagesLinkBottomFooter },
      },
    },
  } = useOptionsPages()

  const currentYear = new Date().getFullYear()

  return (
    <div className="md:flex md:justify-between md:items-center md:gap-3 mt-12 sm:mt-10 pt-6 sm:pt-10 text-text-regular-2 border-t border-secondary-light-s">
      <ul className="flex justify-start md:justify-center items-center mb-2 md:mb-0">
        {pagesLinkBottomFooter?.length > 0
          ? pagesLinkBottomFooter.map(item => {
              return (
                <li
                  key={item?.title}
                  className="[&:not(:last-child)]:mr-2 [&:not(:last-child)]:pr-2 [&:not(:last-child)]:border-r border-text-regular-2 leading-none"
                >
                  <Link
                    to={item?.uri}
                    className={
                      "ss-body-2 ss-link !leading-none transition-opacity hover:opacity-70"
                    }
                    dangerouslySetInnerHTML={{
                      __html: item?.title,
                    }}
                  ></Link>
                </li>
              )
            })
          : null}
      </ul>

      <p className="ss-body-2 flex justify-start items-center space-x-1">
        <span>&copy;</span>
        <span>{currentYear}</span>
        <span>{copyrightInfoBottomFooter}</span>
      </p>
    </div>
  )
}

export default Footer
