const EmailJSSettings = {
  url: 'https://api.emailjs.com/api/v1.0/email/send-form',
  service_id: 'service_5q1ul1a',
  // user_id: 'user_MrlKJvYgLNrgBzOFnev0F',
  user_id: 'U9r3UUojUFnEPLJtr',
  templates: {
    quiz_form: 'template_lhzbfhr',
    subscribe_form: 'template_jbm2gg8',
    ask_form: 'template_by397sl',
    contact_form: 'template_krkfci6',
  }
}

export default EmailJSSettings;
