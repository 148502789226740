import React from "react"

import GroupItemElement from "./GroupItemElement"

const GroupItem = ({ data }) => {
  return (
    <>
      {data.map((item, idx) => {
        return (
          <div key={item.groupName + idx}>
            <GroupItemElement item={item} />
          </div>
        )
      })}
    </>
  )
}

export default GroupItem
