import React, { Fragment } from "react"
import { Link } from "gatsby"
import { Menu, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"

import Svg from "components/SVG"

import stringToSlug from "helpers/stringToSlug"

const Dropdown = ({ item, title }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="ss-button ss-link text-text-title inline-flex w-full justify-center gap-1 px-1 py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
        {title}
        <ChevronDownIcon className="w-6 h-6" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="ss-header-submenu p-6 absolute -left-20 mt-4 lg:w-[30vw] 2xl:w-[400px] origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none space-y-4">
          {item?.dropdownNavigationHeader?.length > 0
            ? item.dropdownNavigationHeader.map(subItem => {
                return (
                  <Menu.Item
                    key={subItem.title}
                    className="mt-2 flex justify-start items-center gap-4 ss-button ss-link text-text-title"
                  >
                    <Link to={subItem.uri}>
                      <Svg
                        name={stringToSlug(subItem.title)}
                        className="text-accent-bright-blue w-8 h-8"
                      />
                      {subItem.title}
                    </Link>
                    {/* {({ active }) => (
                                )} */}
                  </Menu.Item>
                )
              })
            : null}
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown
