import React from "react"
import classnames from "classnames"
import SvgSymbols from "assets/svg/svg-symbols.svg"

const Icon = ({ name, className = "" }) => (
  <svg className={classnames(`ss-icon ss-icon-${name}`, className)}>
    <use xlinkHref={`${SvgSymbols}#${name}`} width="100%" height="100%" />
  </svg>
)

export default Icon
