import React from "react"
import classnames from "classnames"
import { Link } from "gatsby"

import ServicesLink from "../ServicesLink"
import NoServiceLinks from "../NoServiceLinks"

import useMenu from "hooks/graphql/useMenu"

const FooterNav = () => {
  const menus = useMenu()

  return (
    <div>
      <nav className="md:flex">
        <div
          className={classnames(
            "columns-2 gap-10 lg:gap-16 md:mr-10 lg:mr-16 mb-10 md:mb-0"
          )}
        >
          {menus.length > 0
            ? menus.slice(0, 3).map((nav, idx) => {
                return (
                  <ul key={nav.label} className={"break-inside-avoid md:mb-12"}>
                    {nav.label !== "#" ? (
                      <li className="ss-subtitle-1 text-text-regular-2 mb-6">
                        {nav.label}
                      </li>
                    ) : (
                      <></>
                    )}

                    <li>
                      <ul className="space-y-4">
                        {nav.children.length > 0
                          ? nav.children.map(subItem => {
                              return (
                                <li key={subItem.label}>
                                  <Link
                                    to={subItem.path}
                                    className={classnames(
                                      "ss-body-1 ss-link hover:opacity-70 transition-opacity",
                                      {
                                        "after:content-['Coming_soon'] after:text-text-regular-2 after:ss-caption after:ml-4 pointer-events-none":
                                          subItem.connectedNode === null ||
                                          subItem.connectedNode.node === null,
                                      }
                                    )}
                                  >
                                    {subItem.label}
                                  </Link>
                                </li>
                              )
                            })
                          : null}
                      </ul>
                    </li>
                  </ul>
                )
              })
            : null}
        </div>

        <div
          className={classnames(
            "md:mr-10 lg:mr-16 flex md:block gap-10 md:gap-0 mb-10 md:mb-0"
          )}
        >
          {menus.length > 0
            ? menus.slice(3, 6).map((nav, idx) => {
                return (
                  <ul key={nav.label} className={"break-inside-avoid md:mb-12"}>
                    {nav.label !== "#" ? (
                      <li className="ss-subtitle-1 text-text-regular-2 mb-6">
                        {nav.label}
                      </li>
                    ) : (
                      <></>
                    )}

                    <li>
                      <ul className="space-y-4">
                        {nav.children.length > 0
                          ? nav.children.map(subItem => {
                              return (
                                <li key={subItem.label}>
                                  <Link
                                    to={subItem.path}
                                    className={classnames(
                                      "ss-body-1 ss-link hover:opacity-70 transition-opacity",
                                      {
                                        "after:content-['Coming_soon'] after:text-text-regular-2 after:ss-caption after:ml-4 pointer-events-none":
                                          subItem.connectedNode === null ||
                                          subItem.connectedNode.node === null,
                                      }
                                    )}
                                  >
                                    {subItem.label}
                                  </Link>
                                </li>
                              )
                            })
                          : null}
                      </ul>
                    </li>
                  </ul>
                )
              })
            : null}
        </div>

        <NoServiceLinks />
      </nav>

      <ServicesLink />
    </div>
  )
}

export default FooterNav
