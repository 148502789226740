import React, { useMemo } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import useStaticImage from "hooks/graphql/useStaticImage"

const StaticImg = ({
  path = null,
  progressive = null,
  format = "webp",
  alt = "",
  ...props
}) => {
  const getStaticImages = useStaticImage()

  const image = useMemo(
    () => getImage(getStaticImages(path)),
    [getStaticImages, path]
  )

  const desc = !alt ? { role: "presentation", alt: "" } : { alt }

  if (progressive && path) {
    return (
      <picture>
        <source srcSet={progressive} type={`image/${format}`} />
        <img src={path} {...props} alt={alt} />
      </picture>
    )
  } else if (!path || !image)
    return <img src={path} alt={alt} {...props} data-type="img" />

  return <GatsbyImage image={image} {...desc} {...props} />
}

export default StaticImg
