import {
  useCallback
} from "react"

import {
  graphql,
  useStaticQuery
} from "gatsby"

const useStaticImage = () => {
  const {
    allFile: {
      nodes
    },
  } = useStaticQuery(graphql `
    query getImages {
      allFile {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO]
            )
          }
        }
      }
    }
  `)

  return useCallback(
    path => nodes.find(({
      relativePath
    }) => relativePath === path),
    [nodes]
  )
}

export default useStaticImage
