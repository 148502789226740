import React, { useCallback, useState, useRef, useEffect } from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { useLocation } from "@reach/router"

import HeaderNav from "sections/Layout/partials/HeaderNav"
import StaticImg from "components/StaticImg"
import BurgerButton from "sections/Layout/partials/BurgerButton"
import MobileMenu from "sections/Layout/partials/MobileMenu"
import AskModal from "sections/Faq/AskModal"

import useScrollDirection from "hooks/useScrollDirection"
// import useWindowSize from "hooks/useWindowResize"
// import { LG } from "constans/breackpoints"
import useOptionsPages from "hooks/graphql/useOptionsPages"
import useFaqModal from "hooks/graphql/useFaqModal"

import { defaultSurveyButtonTitle } from "constans/common"

const Header = () => {
  const scroll = useScrollDirection()
  // const { width } = useWindowSize()
  const { href } = useLocation()

  const [isOpen, setIsOpen] = useState(false)
  const wrapScroll = useRef(null)

  const {
    header: {
      header: { /*linkHeader,*/ logoHeader },
    },
  } = useOptionsPages()

  const {
    template: {
      faqPage: {
        askQuestionSection: { modalWindowAskQuestion },
      },
    },
  } = useFaqModal()

  // TODO: двойной ререндер компонента
  // console.log(width)

  const burgerHendler = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  // disable page scroll on burger click
  useEffect(() => {
    const refs = wrapScroll.current

    if (isOpen) {
      disableBodyScroll(refs, { reserveScrollBarGap: true })

      return () => {
        enableBodyScroll(refs)
      }
    }
  }, [isOpen])

  useEffect(() => {
    setIsOpen(false)
  }, [href])

  let [isOpenModal, setIsOpenModal] = useState(false)

  const closeModal = useCallback(() => {
    setIsOpenModal(!isOpenModal)
  }, [isOpenModal])

  const openModal = useCallback(() => {
    setIsOpenModal(!isOpenModal)
  }, [isOpenModal])

  return (
    <header
      className={classnames(
        "fixed top-3 lg:top-9 left-0 right-0 transition-all duration-500 delay-200 z-10",
        {
          "top-3 xl:top-9": scroll === "up",
          "!-top-16 xl:!-top-24": scroll === "down",
        }
      )}
    >
      <div className="fluid-container">
        <div className="py-2.5 xl:py-4 px-2.5 xl:px-6 flex justify-between items-center rounded-lg shadow-md shadow-slate-100 bg-white">
          <Link to="/">
            <StaticImg
              path={logoHeader?.sourceUrl}
              alt={logoHeader?.altText}
              className="w-32 xl:w-40"
              width="160"
              height="46"
            />
          </Link>

          {/* {width > LG ? <HeaderNav /> : null} */}
          <div className="hidden xl:block">
            <HeaderNav />
          </div>

          {/* {width > LG ? (
            <Link to={linkHeader?.url} className="ss-btn-base">
              {linkHeader?.title}
            </Link>
          ) : null} */}

          <div className="hidden xl:block">
            {/* <Link to={linkHeader[0]?.uri} className="ss-btn-base">
              {defaultSurveyButtonTitle}
            </Link> */}

            <button className="ss-btn-base" onClick={openModal}>
              {defaultSurveyButtonTitle}
            </button>

            <AskModal
              modalData={modalWindowAskQuestion}
              isOpen={isOpenModal}
              closeModal={closeModal}
            />
          </div>

          {/* {width <= LG ? (
            <BurgerButton isOpen={isOpen} onClick={burgerHendler} />
          ) : null} */}

          <BurgerButton
            isOpen={isOpen}
            onClick={burgerHendler}
            className="block xl:hidden"
          />

          {/* 
          {isOpen && width <= LG ? (
            <div ref={wrapScroll} className="fixed inset-0">
              <MobileMenu />
            </div>
          ) : null} */}

          {isOpen ? (
            <div ref={wrapScroll} className="fixed inset-0 block xl:hidden">
              <MobileMenu />
            </div>
          ) : null}
        </div>
      </div>
    </header>
  )
}

export default Header
