import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { XIcon } from "@heroicons/react/solid"

const Modal = ({
  children,
  closeModal,
  isOpen,
  style = {},
  isCloseBtn = false,
}) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <div className="fixed inset-0 overflow-y-auto bg-black/20">
          <div className="flex min-h-full items-stretch sm:items-center justify-center sm:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                style={style}
                className="flex justify-center items-stretch sm:items-start w-full max-w-md sm:h-full sm:min-h-[640px] transform overflow-hidden sm:rounded-lg bg-white text-left align-middle shadow-xl transition-all"
              >
                {isCloseBtn ? (
                  <button type="button" onClick={closeModal} className="group">
                    <XIcon className="w-6 h-6 absolute top-3 right-3 z-1 group-hover:scale-110 transition-transform" />
                  </button>
                ) : null}

                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Modal
