import React from "react"
import { XIcon } from "@heroicons/react/solid"

import AskForm from "sections/common/Forms/AskForm"

const FormWrap = ({ formData, modalData, isSuccess, closeModal }) => {
  return (
    <div className="h-auto sm:h-full">
      <div className="p-6 bg-main-bg">
        <div className="flex justify-between items-start">
          {modalData?.title ? (
            <h4 className="ss-h4 text-text-title mb-2">{modalData.title}</h4>
          ) : null}

          <button type="button" onClick={closeModal}>
            <XIcon className="w-6 h-6" />
          </button>
        </div>

        {modalData?.subtitle ? (
          <p className="ss-body-1 text-text-regular">{modalData.subtitle}</p>
        ) : null}
      </div>

      <div className="p-6 h-[80%] sm:h-auto">
        <AskForm data={formData} isSuccess={isSuccess} />
      </div>
    </div>
  )
}

export default FormWrap
