import {
  useStaticQuery,
  graphql
} from 'gatsby';

const faqModal = graphql `
  query {
    allWpPage(filter: {template: {templateName: {eq: "FAQ"}}}) {
      nodes {
        template {
          ...on WpTemplate_FAQ {
            templateName
            faqPage {
              askQuestionSection {
                modalWindowAskQuestion {
                  title
                  subtitle
                }
              }
            }
          }
        }
      }
    }
  }
`;

const useFaqModal = () => {
  const {
    allWpPage: {
      nodes
    },
  } = useStaticQuery(faqModal);

  return nodes[0];
};

export default useFaqModal;
