import React from "react"

import SocialsItem from "./SocialsItem"

const SocialsList = ({ data, color }) => {
  return (
    <ul className="flex justify-start items-center gap-4 flex-wrap">
      {data.length > 0
        ? data.map(social => {
            return (
              <li key={social.url}>
                <a href={social.url} target="_blank" rel="noopener noreferrer">
                  <SocialsItem socialsName={social.icon} color={color} />
                </a>
              </li>
            )
          })
        : null}
    </ul>
  )
}

export default SocialsList
